<template>
    <v-col
        class="pl-0 pt-0"
    >
        <v-row>
            <v-card
                width="740"
                height="300"
                class="mx-3 mt-3 pa-1 d-flex"
                flat
                                              
            >
                <v-card
                    width="640"
                    flat
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="table2Records"
                        dense                    
                        fixed-header
                        height="290"
                        disable-pagination
                        hide-default-footer
                        :calculate-widths="true"  
                    >
                        <template v-slot:[`header.subject_id`]="{ header }">
                            <span class="justify-center">{{ header.text }}</span>
                        </template>

                        <template v-slot:[`header.course_mark`]="{ header }">
                            <span class="justify-center">{{ header.text }}</span>
                        </template>

                        <template v-slot:[`header.exam_mark`]="{ header }">
                            <span class="justify-center">{{ header.text }}</span>
                        </template>

                        <template v-slot:item="{ item }">
                            <tr>
                                <td style="text-align:center">{{ item.subject_id}}</td>
                                <td>{{ item.abbr}}</td>
                                <td style="text-align:center">{{ item.course_mark}}</td>
                                <td style="text-align:center">{{ item.exam_mark}}</td>
                                <td style="max-width: 1px; white-space: nowrap; overflow:hidden; text-overflow: ellipsis; ">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                            > 
                                                {{ item.comment1}} 
                                            </span>
                                        </template>
                                        <span>{{ item.comment1}}</span>
                                    </v-tooltip>                                   
                                </td>
                                <td style="max-width: 1px; white-space: nowrap; overflow:hidden; text-overflow: ellipsis; ">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"                                                
                                            > 
                                                {{ item.comment2}} 
                                            </span>
                                        </template>
                                        <span>{{ item.comment2}}</span>
                                    </v-tooltip>                                   
                                </td>
                            </tr>                            
                        </template>
                        
                    </v-data-table>
                </v-card >

                <v-card
                    width="76"
                    flat
                    outlined
                >
                    <v-data-table
                        :headers="headersSubjects"
                        :items="studentSubjects"
                        dense
                        fixed-header
                        height="290"
                        disable-pagination
                        hide-default-footer
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <td style="max-width: 1px; white-space: nowrap; overflow:hidden; text-overflow: ellipsis; ">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                                :class="classStudentSubjects(item)"
                                            > 
                                                {{ item.abbr}} 
                                            </span>
                                        </template>
                                        <span>{{ item.abbr}} - {{item.teacher}}</span>
                                    </v-tooltip>                                   
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
                
                
            </v-card>
            
        </v-row>

        <v-row class="mr-2">
            <v-col>         
                <v-card                                
                    class="d-flex"
                    flat
                    
                >
                    <v-btn                        
                        outlined
                        small
                        color="primary"
                        class="mr-2"
                        @click="classMarkSheet"
                    >
                        Class Mark Sheet
                    </v-btn>
                    
                    <v-btn                        
                        outlined
                        small
                        color="primary"
                        @click="rankSheet"
                    >
                        Rank Sheet
                    </v-btn>

                    <div class="d-flex ml-auto">
                        <span 
                            class="text-caption d-flex align-center"
                        >Promoted To</span>
                        <v-select
                            v-model="studentRecord.promoted_to"
                            hide-details
                            outlined
                            style="width:150px"
                            class="ml-2"
                            :items="promotedClasses"
                            dense
                            small-chips
                            deletable-chips
                            @change="updateRecord"
                            :disabled="!editPromotion"
                            :filled="!editPromotion"
                        ></v-select>
                    </div>
                </v-card>
            </v-col>    
        </v-row>

        <v-row
            class="mt-2" 
        >            
            <v-col class="pa-0">
                <v-card
                    
                    width="450"
                    class="ml-3 mt-4"
                    flat                    
                >
                    <v-form ref="form">
                        <v-textarea                                       
                            label="Form Teacher's Remarks"                            
                            persistent-hint
                            dense
                            rows="2"
                            :value="value"                        
                            counter                                            
                            class="text-caption"
                            :rules="rules"                      
                            no-resize
                            maxlength="215"
                            v-model="studentRecord.comments"                            
                            @blur="saveTeacherComment"
                            @click="openBottomSheet('comments')"
                            :readonly="disabled"
                            :filled="disabled"
                        >                        
                        </v-textarea>
                    </v-form>
                </v-card>
            </v-col>
            <v-col class="pa-0">
                <v-card
                    height="85"
                    width="80"
                    flat
                    outlined
                    class="ml-3"
                >
                    <v-card-title
                        class="pb-0 d-flex justify-center"
                        style="font-size:0.7rem"
                    >
                        Average
                    </v-card-title>
                    <v-card-text
                        class="text-center text-h6 font-weight-bold px-0 primary--text"                        
                    >
                        <span>{{ studentAverage }} % </span>
                    </v-card-text>
                </v-card>
            </v-col>                      
            <v-col class="pa-0 mr-8">
                <v-textarea
                    v-model="studentRecord.subjects_assigned"
                    hide-details
                    outlined
                    label="Subjects Assigned"
                    rows="4"
                    style="width:150px"
                    @blur="updateSubjectsAssigned"
                    placeholder=" "
                    :readonly="!editPromotion"
                    :filled="!editPromotion"
                ></v-textarea>
            </v-col>                      
                                 
        </v-row>

        <v-row>
            <v-col class="py-0 caption d-flex justify-end">                              
                {{ saveStatus }}
                
                    <v-progress-circular
                        indeterminate
                        v-show="saving"
                        size="16"
                        width="3"
                        class="ml-3"
                        color="primary"
                    ></v-progress-circular>
                
                    
                    <v-icon
                        small
                        color="primary"
                        v-show="saved"
                        class="ml-3"
                    >
                        mdi-check-all
                    </v-icon>
                 
                    <v-icon
                        small
                        color="red"                   
                        v-show="error"
                        class="ml-3"
                    >
                        mdi-alert-circle
                    </v-icon>
                    
            </v-col>
        </v-row>        

    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default { 
    created: function () {
        this.initialize();
    },

    data: () => ({
        headers: [],
        headersSubjects:[],
        subjectRecords: [],        
        studentAverage: '',
        studentPasses: null,
        studentDistinctions: '-',
        imageSrc: null,        
        value: ' ', 
        rules: [],
        isValid: true, 
        record: {},
        subjectRecord: [],
        disabled: true,
        displayAverage: false,
        displayGrade: false,
        displayPasses: false,
        displayDistinctions: false,
        formLevel: null,
        btnClass: 'text-caption pa-2  white--text',
        dark: false,
        recordSummary: [            
            {title: 'Library', model: 'pe_dance', select: true, disabled: false, section: 2},
        ],
        grades: [
            {grade: 'A', scale: '80 - 100'},
            {grade: 'B', scale: '70 - 79'},
            {grade: 'C', scale: '60 - 69'},
            {grade: 'D', scale: '55 - 59'},
            {grade: 'E', scale: '50 - 54'},
            {grade: 'F', scale: '0 - 49'},
            {grade: 'U', scale: 'Ungraded'},            
            {grade: 'N/A', scale: 'Not Assessed'},            
            {grade: 'Abs', scale: 'Absent'},            
        ],
        libraryComments: [],
        codedComment: null,
        dialogReport: false,
        src: null,
        overlayReport: false,
        select: [],
        subjects: [],
        dean: '',
        editPromotion: false,      
    }),

    watch: {
        studentRecord: {
            handler(val){
                // console.log(val);                
                this.formLevel = val.form_level;                
                
                if(this.formTeacherClass != this.studentRecord.class_id){
                    //console.log('Not the form Teacher');
                    this.disabled = true;
                }
                else{
                    //console.log('Assigned Form Teacher');
                    this.disabled = false;
                }
                this.setTableHeaders();
                //this.setTableSubjectMarksEntered();
                this.editPromotion = false;
                if(this.dean == this.studentRecord.form_level){
                    this.editPromotion = true;
                }
            }
        },
        
        table2Records: {
            handler () {                
                this.getAverage();
            }
        },
       
    },
    computed: {
        ...mapGetters({
            sheet: 'termReports/getCommentSheet',
            formTeacherComments: 'termReports/getFormTeacherComments',
            table2Records: 'termReports/getEditViewTermReportsTable2Records',
            saveStatus: 'termReports/getEditViewTermReportsSaveStatus',
            saving: 'termReports/getEditViewTermReportsSaving',
            error: 'termReports/getEditViewTermReportsSaveError',
            saved: 'termReports/getEditViewTermReportsSaved',
            studentRecord: 'termReports/getSelectedStudentRecord',
            formTeacherClass: 'termReports/getFormTeacherClass',
            formClasses: 'termReports/getFormClasses',
            currentTerm: 'termReports/getCurrentTerm',
            currentYear: 'termReports/getCurrentYear',
            pagination: 'termReports/getPagination',
            selectedFormClass: 'termReports/getSelectedFormClass',
            codedComments: 'termReports/getCodedComments',
            table1Records: 'termReports/getTable1Records',
            studentSubjects: 'termReports/getEditViewTermReportsStudentSubjects',
            termConfiguration: 'termReports/getTermConfiguration',                        
        }),

        studentGrade(){
            //console.log(this.studentAverage);           
            if(this.studentAverage != ' '){
                 if(this.formLevel < 4){
                    if(this.studentAverage > 74) return 'A';
                    else if(this.studentAverage > 64) return 'B';
                    else if(this.studentAverage > 55) return 'C';
                    else if(this.studentAverage > 45) return 'D';
                    else if(this.studentAverage >=0 ) return 'E';
                 }
                 else if(this.formLevel >= 4){
                    if(this.studentAverage > 74.9) return 'I';
                    else if(this.studentAverage > 59.9) return 'II';
                    else if(this.studentAverage > 49.9) return 'III';
                    else if(this.studentAverage > 39.9) return 'IV';
                    else if(this.studentAverage > 29.9) return 'V';
                    else if(this.studentAverage > 19.9) return 'VI';
                    else if(this.studentAverage >= 0) return 'U';
                 }                
            }           
            
            return '-';
        },
        
        promotedClasses () {
            let classes = [];
            this.formClasses.forEach(value => {
                if(value.title == 'Form 4'){
                    value.items.forEach(item => {
                        classes.push(item.title)
                    })
                }
            })
            return classes;
        }
        
    },
    methods: {
        ...mapMutations({
            setCommentSheet: 'termReports/setCommentSheet',
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setPagination: 'termReports/setPagination',
            overlay: 'termReports/setEditViewTermReportsOverlay',
            loading: 'termReports/setEditViewTermReportsLoading',
            setSelectedStudentRecord: 'termReports/setSelectedStudentRecord',
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setBottomSheetModel: 'termReports/setBottomSheetModel',
            setBottomSheetTitle: 'termReports/setBottomSheetTitle',
            setExpandTerms: 'termReports/setExpandTerms',
            setSrc: 'termReports/setSrc',    
        }),

        ...mapActions({
            postTable1Record: 'termReports/postTable1Record',
            getTable1Record: 'termReports/getTable1Record',
            setErrorResponse: 'termReports/setErrorResponse',            
            getSubjectMarksEntered: 'termReports/getSubjectMarksEntered',
            getSubjects: 'termDetails/getSubjects',
            getDeanAssignment: 'termDetails/getDeanAssignment', 
        }),

        async initialize () {
            console.log('initializing...');
            this.setDean();
            // try {
            //     const { data } = await this.getSubjects();
            //     this.subjects = data;
            // } catch (error) {
            //     if(error.response) console.log(error.response);
            //     else console.log(error);
            // }
            // console.log(this.formClasses);
        },

        getAverage () {
            let recordCount = 0, markTotal = 0;            
            let average = 0;
            if(this.termConfiguration && !this.termConfiguration.exam_mark){
                this.table2Records.forEach(record => {
                    recordCount++;                
                    markTotal += record.course_mark
                })
            }
            else{
                this.table2Records.forEach(record => {
                    recordCount++;                
                    markTotal += record.exam_mark
                })
            }
                  
            if(recordCount != 0){
                average = (markTotal / recordCount);
                //this.studentAverage = average + ' %';
                this.studentAverage = average.toPrecision(3) ;
            }
            else{
                this.studentAverage = ' '
            }

            //console.log(this.studentAverage);
            
        },
        
        saveTeacherComment(){
            //console.log('saving...');
            //console.log(this.$refs.form.validate());
            if(!this.disabled) this.updateRecord();
        },

        openBottomSheet(model){
            this.setBottomSheetModel(model); 
            if(model == 'comments'){
                this.setBottomSheetTitle('Form Teacher Comments');
            }
            else{
                this.setBottomSheetTitle('General Remarks: Conduct');
            }
            if(!this.disabled) this.setCommentSheet(true);
        },

        async updateRecord(){
            console.log('updating...');
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            //this.studentRecord.lib_comment = this.codedComment.id;           
            //console.log(this.studentRecord);
            try{
                await this.postTable1Record();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                //console.log(response);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }            
        },

        setTableHeaders(){
           this.headers = [
                {text: 'Code', align: 'center', value: 'subject_id', width: '50', sortable: false},
                {text: 'Subject', align: 'start', value: 'abbr', width: '80', sortable: false},
                {text: 'Course', align: 'center', value: 'course_mark', width: '50', sortable: false},
                {text: 'Exam', align: 'center', value: 'exam_mark', width: '50', sortable: false},
                {text: 'Comment1', align: 'start', value: 'comment1', width: '165', sortable: false},
                {text: 'Comment2', align: 'start', value: 'comment2', width: '165', sortable: false}               
            ];
            
            this.headersSubjects = [{text: 'Subjects', value: 'abbr', sortable:false}]
        }, 

        classMarkSheet () {
            this.overlay(true);
            this.$nextTick(() => {
                this.setExpandTerms(true);
            });

            this.setSrc({
                baseUrl: process.env.VUE_APP_API_URI  + '/api/mark-sheet',
                year: this.currentYear,
                term: this.currentTerm,
                classId: this.studentRecord.class_id               
            });

        },

        rankSheet () {            
            this.overlay(true);
            this.$nextTick(() => {
                this.setExpandTerms(true);
            });
            
            this.setSrc({
                baseUrl: process.env.VUE_APP_API_URI  + '/api/rank-sheet',
                year: this.currentYear,
                term: this.currentTerm,
                classId: this.studentRecord.class_id               
            });           
           
        },       
       
        async setDean () {
            try {
                const { data } = await this.getDeanAssignment();
                if(data) this.dean = data.form_level;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        updateSubjectsAssigned () {
            !this.editPromotion || this.updateRecord();
        },

        classStudentSubjects (item) {
            if(item.entered == 1) return "font-weight-bold primary--text";
            return "font-weight-bold red--text";
        },
    }
}
</script>

<style scoped>    

    ::v-deep .v-input__slot{
        min-height: 0px !important;
        display: flex !important;
        align-items: center !important;
    }

    .v-btn__content{
        font-size: 0.8em;
    }

    ::v-deep .v-input__append-inner{
        margin-top: 0;
    }

    ::v-deep .v-select__selections{
        height: 30px;
        /*padding: 0 !important;*/
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    ::v-deep .v-text-field__slot input{
        text-align: center;
    }

    
    ::v-deep .v-text-field__slot textarea{
        line-height: 1.2rem;
        font-size: 13px;
    }

    ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        padding: 0 6px;
        font-size: 0.7rem;
        text-align: center;
    }

    ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 6px;
        font-size: 0.75rem;
    }

    ::v-deep .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
        height: 20px;
    }

    ::v-deep .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > th {
        height: 22px;
    }

    ::v-deep textarea {
        padding-top: 8px;
    }

</style>