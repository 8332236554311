import axios from 'axios'

export default {
    namespaced: true,

    state: {
        authenticated: sessionStorage.authenticated || false,
        user: {employee_id: null, employee_name: null},
        userName: '',    
        userRecord: null,       
        overlay: false,
        dialog: false,
        loginErrors: '',
        resetPassword: false,
        resetPasswordShow: false,
        newPassword: '',
        displayPasswordChanged: false,        
        step: 1,
        employeeId: sessionStorage.employeeId || null,
        employeeSignedIn: '',
        employeeInitials: '',
        validUserName: false,
        expand: true,              
    },

    getters: {
        getAuthenticated (state) {
            return state.authenticated
        },

        getUser (state) {
            return state.user
        },     

        getUserRecord(state){
            return state.userRecord
        },

        getOverlay(state){
            return state.overlay
        },       

        getDialog(state){
            return state.dialog
        },

        getLoginErrors(state){
            return state.loginErrors
        },

        getResetPassword(state){
            return state.resetPassword
        },

        getNewPassword(state){
            return state.newPassword
        },

        getDisplayPasswordChanged(state){
            return state.displayPasswordChanged
        },        

        getStep(state){
            return state.step
        },
        
        getEmployeeId(state){
            return state.employeeId
        },

        getEmployeeSignedIn(state){
            return state.employeeSignedIn
        },

        getEmployeeInitials(state){
            return state.employeeInitials
        },
        
        getValidUserName(state){
            return state.validUserName
        },

        getUserName(state){
            return state.userName
        },

        getResetPasswordShow(state){
            return state.resetPasswordShow
        },
        getExpand(state){
            return state.expand
        }
    },

    mutations: {
        setAuthenticated (state, value) {
            state.authenticated = value
        },

        setUser (state, value) {
            state.user = value
        },

        setUserId (state, value){
            state.user.student_id = value;
        },

        setUserRecord (state, value){
            state.userRecord = value
        },

        setOverlay (state, value){
            state.overlay = value
        },
        
        setDialog(state, value){
            state.dialog = value
        },

        setLoginErrors(state, value){
            state.loginErrors = value
        },

        setResetPassword(state, value){
            state.resetPassword = value
        },

        setNewPassword(state, value){
            state.newPassword = value
        },

        setDisplayPasswordChanged(state, value){
            state.displayPasswordChanged = value
        },        

        setStep(state, value){
            state.step = value
        },

        setEmployeeId(state, value){
            state.employeeId = value
        },

        setEmployeeSignedIn(state, value){
            state.employeeSignedIn = value
        },

        setEmployeeInitials(state, value){
            state.employeeInitials = value
        },

        setValidUserName(state, value){
            state.validUserName = value
        },

        setUserName(state, value){
            state.userName = value
        },

        setExpand(state, value){
            state.expand = value
        }
    },

    actions: {        

        async adminSignIn({ commit, dispatch }, credentials){
            let response = null;
            commit('setOverlay', true);
            try {
                await axios.get('/sanctum/csrf-cookie');
                response = await axios.post('/api/admin-login', credentials);
                console.log(response.data);
                sessionStorage.authenticated = response.data;
                commit('setLoginErrors', '')
                commit('setAuthenticated', true);
                commit('setUser', credentials);
                commit('setOverlay', false);
            } catch (error) {
                dispatch('serverError', error);
                commit('setOverlay', false);
            }            
                 
        },

        async employeeSignIn({ commit, dispatch }, credentials){
            let response = null;
            commit('setOverlay', true);
            try {
                await axios.get('/sanctum/csrf-cookie');
                response = await axios.post('/api/employee-login', credentials);
                console.log(response.data);
                sessionStorage.authenticated = response.data;
                sessionStorage.employeeId = response.data[0].employee_id;
                commit('setAuthenticated', true);
                commit('setEmployeeId', response.data[0].employee_id);
                commit('setLoginErrors', "");                
            } catch (error) {
                dispatch('serverError', error);
                commit('setOverlay', false);
            }            
            
        },

        async employee({ getters, commit }){
            let url = '/api/employee/' + getters.getEmployeeId;
            let response = await axios.get(url);
            let name = '';
            let initials = '';
            if(response.data.length > 0){
                name = response.data[0].first_name + ' ' + response.data[0].last_name;
                initials = response.data[0].first_name[0] + response.data[0].last_name[0];
            }            
            commit('setEmployeeSignedIn', name);
            commit('setEmployeeInitials', initials);                        
        },        

        async user({ getters }){           
            console.log("getting user information...");
            let url = '/api/user-employee/' + getters.getUserName;            
            return axios.get(url);           
        },

        async changePassword({ getters }){            
            let url = '/api/employee-change-password';
            return await axios.post(url, {
                "name" : getters.getUserName,
                "password" : getters.getNewPassword,
            })            
        },        

        async signOut({commit}){
            let response = null;
            try {
                response = await axios.post('/logout');
                console.log(response.status);                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);                
            }
            commit('setAuthenticated', false);                       
            sessionStorage.clear();           
        },        
        
       checkSession({dispatch}){           
           console.log(`Cookie Set: ${dispatch('cookieSet', 'ans_api_session')}`);
       },      

       async changeResetPassword({ commit, getters }, resetPassword){
            commit('setOverlay', true);
            await axios.post('/api/change-reset-password',{
                "student_id" : getters.getUserId,
                "reset_password" : resetPassword,
            });
            commit('setResetPassword', resetPassword);
            commit('setDisplayPasswordChanged', true);
            commit('setOverlay', false);
       },

       cookieSet(cookieName){
           let name = cookieName + "=";
           let decodedCookie = decodeURIComponent(document.cookie);
           let cookies = decodedCookie.split(';');
           for(var i = 0; i < cookies.length; i++){
               var c = cookies[i];
               while(c.charAt(0) == ' '){
                   c = c.substring(1);
               }
               if(c.indexOf(name) == 0){
                    return true;
               }
           }
           return false;
       },

       serverError({ commit }, err ){
            console.log(err.response)
            //var hasErrors = Object.prototype.hasOwnProperty.call(err.response, 'errors');                                              
            if( err.response){
                commit('setLoginErrors', err.response.data.message);
            }
            else{
                commit('setLoginErrors', err.response.statusText);
            }                    
            commit('setAuthenticated', false);
            commit('setOverlay', false);
       }
    }
}