<template>
    <v-app>
        <v-app-bar 
            app
            color="secondary"
            dark
            clipped-left
            elevation="0"
        > 
            <div class="d-flex align-center primary--text text-uppercase font-weight-bold">
                <v-img
                alt="Vuetify Logo"
                class="shrink mr-2"
                contain
                src="../assets/logo.png"
                transition="scale-transition"
                width="50"
                />
                {{ schoolName }}
                
            </div>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                        color="primary"
                        class="mr-6"
                        size="36" 
                        v-bind="attrs"
                        v-on="on"               
                    >
                        <span class="secondary--text button">{{ initials }}</span>
                    </v-avatar>                
                </template>
                <span>{{ user }}</span>
            </v-tooltip>

            <Logout component="term-reports" />
        </v-app-bar>

        <v-main class="">
            <v-container
                fluid                
            >
                <v-row 
                    v-if="welcomeMessage" 
                    class="mt-2 ml-4 mb-6"
                    justify="space-between"
                >
                    <div :class="textClass">Welcome, <span class="font-weight-light font-italic text-h5">{{ user }}</span></div>                                             
                    <div class="text-body-1 primary--text mr-4" >Term {{ currentTerm }}, {{ academicYear }} </div>
                </v-row>
                <v-row>
                    <main-menu v-if="mainMenu"></main-menu>
                </v-row>
                <v-row>                
                    <enter-marks v-if="enterMarks"></enter-marks>
                </v-row>
                <v-row>
                    <edit-view-term-reports v-if="editViewTermReports"></edit-view-term-reports>
                </v-row>
                <v-row>
                    <PrintViewReports v-if="printViewReports"/>
                </v-row>                
            </v-container> 
        </v-main>

        <sweet-modal
            :icon="modalIcon"
            ref="modal"
            overlay-theme="dark"
            :title="modalTitle"
        >
            {{ modalMessage }}
        </sweet-modal>

        <v-overlay
            :value="progressOverlay"
            opacity="0.8"
            z-index="10"                    
        >
            
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>                   
        </v-overlay>
    </v-app>
</template>

<script>
import Logout from './auth/Logout';
import MainMenu from './MainMenu';
import EnterMarks from './EnterMarks';
import EditViewTermReports from './EditViewTermReports';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { SweetModal } from 'sweet-modal-vue';
import PrintViewReports from './PrintViewReports.vue';

export default {
    name: 'Admin',
    components: {
        Logout,        
        MainMenu,
        EnterMarks,
        EditViewTermReports,
        SweetModal, 
        PrintViewReports       
    },

    mounted(){ 
        this.loadTermReports();        
    },

    watch: {
        modal:{
            handler(value){
                console.log('opening modal..')
                if(value) this.openModal();
            }
        }
    },
    computed: { 
        ...mapGetters({
            getUser: 'termReports/getUser',
            schoolName: 'termReports/getSchoolName', 
            user: 'auth/getEmployeeSignedIn',
            initials: 'auth/getEmployeeInitials',
            enterMarks: 'termReports/getEnterMarks',
            editViewTermReports: 'termReports/getEditViewTermReports',
            mainMenu: 'termReports/getMainMenu',
            term: 'termReports/getCurrentTerm',
            year: 'termReports/getCurrentYear',
            modal: 'termReports/getModal',
            modalTitle: 'termReports/getModalTitle',
            modalIcon: 'termReports/getModalIcon',
            modalMessage: 'termReports/getModalMessage',
            welcomeMessage: 'termReports/getWelcomeMessage',
            progressOverlay: 'termReports/getProgressOverlay',
            currentTerm: 'termReports/getCurrentTerm',
            academicYear: 'termReports/getAcademicYear',  
            printViewReports: 'termReports/getPrintViewReports',          
        }),

        titleClass(){
            return 'subtitle-1 font-weight-light font-italic primary--text';
        },

        textClass(){
            return 'text-body-1 primary--text ';
        }        
    },
    
    data: () => ({                
        lastLogin: false,    
    }),
    
    methods: {
        ...mapMutations({
            setMainMenu: 'termReports/setMainMenu',
            setEnterMarks: 'termReports/setEnterMarks',
            setOverlay: 'termReports/setProgressOverlay',
        }),

        ...mapActions({
            currentPeriod: 'termReports/getCurrentAcademicPeriod',
            employee: 'auth/employee',
        }),
        
        async loadTermReports(){
            this.setOverlay(true);
            try {
                const currentPeriodPromise =  this.currentPeriod();
                const employeePromise = this.employee();
                await currentPeriodPromise;
                await employeePromise;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }            
            this.setOverlay(false);
        },
        openModal(){            
            this.$refs.modal.open();
        }
    }
}
</script>