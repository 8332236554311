<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col>
                <v-card
                    width="70"
                    flat
                    class="d-flex flex-column align-center grey lighten-5"
                    v-show="mainMenuButton"
                >
                    <v-expand-transition>
                        <v-btn
                            color="primary"
                            dark
                            fab
                            small
                            @click="showMainMenu"    
                        >
                            <v-icon>
                                mdi-arrow-left
                            </v-icon>
                            
                        </v-btn>
                    </v-expand-transition>
                    <div
                        class="caption mt-2"
                    >
                        Main Menu
                    </div>
                </v-card>
            </v-col>
            <v-col>
                <v-card width="500">
                    <v-toolbar 
                        flat 
                        color="primary" 
                        dark
                    >
                        <v-toolbar-title>Print View Reports</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-6">
                        <div>
                            <v-select
                                v-model="reportSelected"
                                :items="reports"
                                label="Select Report"
                                outlined
                            ></v-select>
                        </div>

                        <div v-if="showClasses">
                            <v-select
                                v-model="classSelected"
                                :items="formClasses"
                                label="Select Class"
                                item-text="id"
                                item-value="id"
                                outlined
                            ></v-select>
                        </div>

                        <div>
                            <v-radio-group v-model="radioGroup" row>
                                <v-radio label="PDF" value="pdf"></v-radio>
                                <v-radio label="Excel" value="csv"></v-radio>
                            </v-radio-group>
                        </div>
                    </v-card-text>

                    <v-card-actions class="px-4">
                        <v-spacer></v-spacer>
                        <v-btn 
                            color="primary"
                            @click="displayReport"
                        >
                            {{ buttonText }}
                        </v-btn>
                    </v-card-actions>

                    <v-overlay 
                        :value="overlay" 
                        absolute
                    >
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-col>
            <v-col></v-col>
        </v-row>

        <v-dialog
            v-model="dialog"
            width="60vw"
            persistent
        >
            <v-card v-if="dialog">
                <v-card-text class="px-0 pb-2">
                    <v-card height="80vh">
                        <iframe 
                            ref="pdf" 
                            style="width:100%; height:100%" 
                            frameBorder="0" 
                            :src="src"
                            @load="pdfLoaded"
                        ></iframe>
                    </v-card>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        color="primary"
                        @click="dialog = false"
                    >
                        Close Report
                    </v-btn>
                </v-card-actions>

                <v-overlay 
                    :value="overlayReport"
                    color="white"
                    absolute
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
            </v-card>


        </v-dialog>
        
    </v-container>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    data: function () {
        return {
            reports: ['Class List'],
            formClasses: [],
            buttonText: 'Display Report',
            radioGroup: 0,
            reportSelected: null,
            showClasses: false,
            classSelected: null,
            overlay: false,
            reportTitle: null,
            src: null,
            dialog: false,
            overlayReport: false,
        }
    },

    watch: {
        radioGroup: {
            handler (val) {
                if(val == "pdf") this.buttonText = "Display Report";
                if(val == "csv") this.buttonText = "Download Report";
            }
        },

        reportSelected: {
            handler (val){
                switch (val) {
                    case "Class List":
                        this.initializeClassList();
                        break;
                
                    default:
                        break;
                }
            }
        }
    },

    computed: {
        ...mapGetters({
            mainMenuButton: 'termReports/getMainMenuButton',
        })
    },

    methods: {
        ...mapMutations({
            setPrintViewReports: 'termReports/setPrintViewReports',
            setMainMenu: 'termReports/setMainMenu',
            setWelcomeMessage: 'termReports/setWelcomeMessage',
            setClassSelected: 'printViewReports/setClassSelected',
        }),

        ...mapActions({
            getFormClasses: 'printViewReports/getFormClasses',
            downloadClassList: 'printViewReports/downloadClassList',
        }),

        showMainMenu(){
            this.setPrintViewReports(false);            
            this.setWelcomeMessage(true);
            this.setMainMenu(true); 
        },

        async initializeClassList (){
            this.overlay = true;
            this.reportTitle = "Class List";
            this.showClasses = true;
            try {
                const { data } = await this.getFormClasses();
                this.formClasses = data;
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
            }
            this.overlay = false;
        },

        async displayReport () {
            this.setClassSelected(this.classSelected)
            const baseUrl = process.env.VUE_APP_API_URI  + '/api/';
            if(this.radioGroup == "pdf"){
                this.src = baseUrl + "class-list?class_id=" + this.classSelected;
                this.overlayReport = true;
                this.dialog= true;
            }
            else if(this.radioGroup == "csv"){
                this.overlay = true;
                try {
                    const { data } = await this.downloadClassList(); 
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(
                        new Blob([data])
                    );
                    link.setAttribute('download', 'Class List.xlsx');
                    document.body.appendChild(link);
                    link.click();
                } catch (error) {
                    error.response ? console.log(error.response) : console.log(error)
                }
                this.overlay = false;
            } 
        },

        pdfLoaded (){
            this.overlayReport = false;
        },
    }
}
</script>