<template>
    <v-container class="pb-0">
        <v-data-table
            :items="table2Records"
            :loading="loading"
            :headers="headers"
            :height="height"            
            fixed-header           
            class="enter-marks-table"
            disable-pagination
            hide-default-footer 
            ref="table"
        >
            <template v-slot:item="{ item, rowIndex }">
                <Record
                    v-bind:studentRecord="item"
                    v-bind:row-index="rowIndex"
                />
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import Record from './EnterMarksSheetRecord';
import { mapGetters } from 'vuex';
export default {
    components: {
        Record,
    },

    created () {
        //this.initialize();
    },

    data: () =>({
        loading: false,
        height: "45vh",
        pagination: false,
        footer: false,                
        headers: [],       
        
    }),

    computed:{
        ...mapGetters({            
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm',
            lessonSelected: 'termReports/getLessonSelected',
        }),
    },

    watch: {
        lessonSelected: {
            handler () {
                this.initialize();
            }
        },       
    },
    methods: { 
       initialize(){
           this.headers = [
                {text: '#', value: 'count', width: '60', sortable: false},
                {text: 'Name', value: 'name',  sortable: false},
                {text: 'Course', value: 'course', align: 'center', width: '100', sortable: false,},            
                {text: 'Exam', value: 'exam', align: 'center', width: '100', sortable: false,},                    
                {text: 'Coded Comment', value: 'codedComment1',  width: '170', sortable: false,},            
                {text: 'Coded Comment 1',  value: 'codedComment2', width: '170', sortable: false,},
                {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},                        
            ]           
       }
    }
}
</script>

<style>
    .v-text-field__slot textarea{
        font-size: 13px;
    }
    
    th span{
        display: flex;
    }

    .v-data-table > .v-data-table__wrapper > table{
        padding-right: 24px
    }

    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        font-size: 0.9rem;
        font-weight: bold;
        color: rgb(80 80 80);
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-child(odd){
        background-color: rgb(250, 250, 250);
    }

    

</style>