import axios from 'axios'

export default{
    namespaced: true,
    state: { 
        progressOverlay: false,       
        enterMarks: false,
        editViewTermReports: false,
        mainMenu: true,
        primaryColor: process.env.VUE_APP_THEME_COLOR_PRIMARY,
        colorDarken: process.env.VUE_APP_THEME_COLOR_DARKEN,
        schoolName: process.env.VUE_APP_THEME_SCHOOL,
        lessons: [],
        lessonSelected: {},
        academicYearId: '',
        academicYear: '',
        currentYear: '',
        currentTerm: '',
        students: [],
        table2Records: [],
        markSheetOverlay: false,
        markSheetLoading: false,
        markSheetStudentSelected: '',
        markSheetFormLevel: '',
        expandLessonOptions: false,
        mainMenuButton: true,        
        codedComments: [],
        enterMarksRecordSelected: {},
        updatedTable2Record: {},
        errorMessage: '',
        modal: false,
        modalIcon: '',
        modalTitle: '',
        modalMessage: '',
        welcomeMessage: true,
        subjectStudentsRegistered: '-',
        subjectStudentsMarksEntered: '-',
        subjectStudentsTotal: '-',
        editViewTermReportsOverlay: false,
        editViewTermReportsLoading: false,
        editViewTermReportsExpand: false,
        editViewTermReportsTable2Records: [],
        editViewTermReportsStudentSubjects: [],
        editViewTermReportsSaveStatus: '',
        editViewTermReportsSaving: false,
        editViewTermReportsSaveError: false,
        editViewTermReportsSaved: false,
        formClasses: [],
        formClassesList: [],
        selectedFormClass: '',
        selectedStudentRecord: {},
        pagination: {},
        commentSheet: false,
        formTeacherComments: '',
        updatedTable1Record: {},
        formTeacherClass: '',
        bottomSheetModel: '',
        bottomSheetTitle: '',
        table1Records: [],
        expandTerms: false,
        termsAvailable: [],        
        dialogReport: false,
        src: null,
        overlayReport: false,
        termConfiguration: {},
        printViewReports: false,
    },
    getters: {
        getEnterMarks(state){
            return state.enterMarks
        },
        getEditViewTermReports(state){
            return state.editViewTermReports
        },
        getMainMenu(state){
            return state.mainMenu
        },
        getPrimaryColor(state){
            return state.primaryColor
        },
        getColorDarken(state){
            return state.colorDarken
        },
        getSchoolName(state){
            return state.schoolName
        },
        getLessons(state){
            return state.lessons
        },
        getLessonSelected(state){
            return state.lessonSelected
        },
        getAcademicYearId(state){
            return state.academicYearId
        },
        getAcademicYear(state){
            return state.academicYear
        },
        getCurrentYear(state){
            return state.currentYear
        },
        getCurrentTerm(state){
            return state.currentTerm
        },
        getStudents(state){
            return state.students
        },
        getMarkSheetOverlay(state){
            return state.markSheetOverlay
        },
        getTable2Records(state){
            return state.table2Records
        },
        getExpandLessonOptions(state){
            return state.expandLessonOptions
        },
        getMainMenuButton(state){
            return state.mainMenuButton
        },
        getMarkSheetLoading(state){
            return state.markSheetLoading
        },
        getCodedComments(state){
            return state.codedComments
        },
        getEnterMarksRecordSelected(state){
            return state.enterMarksRecordSelected
        },
        getUpdatedTable2Record(state){
            return state.updatedTable2Record
        },
        getErrorMessage(state){
            return state.errorMessage
        },
        getModal(state){
            return state.modal
        },
        getModalIcon(state){
            return state.modalIcon
        },
        getModalTitle(state){
            return state.modalTitle
        },
        getModalMessage(state){
            return state.modalMessage
        },
        getWelcomeMessage(state){
            return state.welcomeMessage
        },
        getSubjectStudentsRegistered(state){
            return state.subjectStudentsRegistered
        },
        getSubjectStudentsMarksEntered(state){
            return state.subjectStudentsMarksEntered
        },
        getSubjectStudentsTotal(state){
            return state.subjectStudentsTotal
        },
        getMarkSheetStudentSelected(state){
            return state.markSheetStudentSelected
        },
        getMarkSheetFormLevel(state){
            return state.markSheetFormLevel
        },
        getEditViewTermReportsOverlay(state){
            return state.editViewTermReportsOverlay
        },
        getEditViewTermReportsLoading(state){
            return state.editViewTermReportsLoading
        },
        getEditViewTermReportsExpand(state){
            return state.editViewTermReportsExpand
        },
        getFormClasses(state){
            return state.formClasses
        },
        getFormClassesList(state){
            return state.formClassesList
        },
        getSelectedFormClass(state){
            return state.selectedFormClass
        },
        getSelectedStudentRecord(state){
            return state.selectedStudentRecord
        },
        getPagination(state){
            return state.pagination
        },
        getCommentSheet(state){
            return state.commentSheet
        },
        getFormTeacherComments(state){
            return state.formTeacherComments
        },
        getEditViewTermReportsTable2Records(state){
            return state.editViewTermReportsTable2Records
        },
        getEditViewTermReportsStudentSubjects (state) {
            return state.editViewTermReportsStudentSubjects
        },
        getUpdatedTable1Record(state){
            return state.updatedTable1Record
        },
        getEditViewTermReportsSaveStatus(state){
            return state.editViewTermReportsSaveStatus
        },
        getEditViewTermReportsSaving(state){
            return state.editViewTermReportsSaving
        },
        getEditViewTermReportsSaveError(state){
            return state.editViewTermReportsSaveError
        },
        getEditViewTermReportsSaved(state){
            return state.editViewTermReportsSaved
        },
        getFormTeacherClass(state){
            return state.formTeacherClass
        },
        getProgressOverlay(state){
            return state.progressOverlay
        },
        getBottomSheetModel(state){
            return state.bottomSheetModel
        },
        getBottomSheetTitle(state){
            return state.bottomSheetTitle
        },
        getTable1Records(state){
            return state.table1Records
        },
        getExpandTerms (state) {
            return state.expandTerms
        },

        getTermsAvailable (state) {
            return state.termsAvailable
        },
        
        getDialogReport (state) {
            return state.dialogReport
        },

        getSrc (state) {
            return state.src
        },

        getOverlayReport (state) {
            return state.overlayReport
        },

        getTermConfiguration (state) {
            return state.termConfiguration
        },

        getPrintViewReports (state){
            return state.printViewReports
        }
    },
    mutations: {
        setEnterMarks(state, value){
            state.enterMarks = value
        },
        setEditViewTermReports(state, value){
            state.editViewTermReports = value
        },
        setMainMenu(state, value){
            state.mainMenu = value
        },
        setLessons(state, value){
            state.lessons = value
        },
        setLessonSelected(state, value){
            state.lessonSelected = value
        },
        setAcademicYearId(state, value){
            state.academicYearId = value
        },
        setAcademicYear(state, value){
            state.academicYear = value
        },
        setCurrentYear(state, value){
            state.currentYear = value
        },
        setCurrentTerm(state, value){
            state.currentTerm = value
        },
        setStudents(state, value){
            state.students = value
        },
        setMarkSheetOverlay(state, value){
            state.markSheetOverlay = value
        },
        setTable2Records(state, value){
            state.table2Records = value
        },
        setExpandLessonOptions(state, value){
            state.expandLessonOptions = value
        },
        setMainMenuButton(state, value){
            state.mainMenuButton = value
        },
        setMarkSheetLoading(state, value){
            state.markSheetLoading = value
        },
        setCodedComments(state, value){
            state.codedComments = value
        },
        setEnterMarksRecordSelected(state, value){
            state.enterMarksRecordSelected = value
        },
        setUpdatedTable2Record(state, value){
            state.updatedTable2Record = value
        },
        setErrorMessage(state, value){
            state.errorMessage = value
        },
        setModal(state, value){
            state.modal = value
        },
        setModalTitle(state, value){
            state.modalTitle = value
        },
        setModalIcon(state, value){
            state.modalIcon = value
        },
        setModalMessage(state, value){
            state.modalMessage = value
        },
        setWelcomeMessage(state, value){
            state.welcomeMessage = value
        },
        setSubjectStudentsTotal(state, value){
            state.subjectStudentsTotal = value
        },
        setSubjectStudentsRegistered(state, value){
            state.subjectStudentsRegistered = value
        },
        setSubjectStudentsMarksEntered(state, value){
            state.subjectStudentsMarksEntered = value
        },
        setMarkSheetStudentSelected(state, value){
            state.markSheetStudentSelected = value
        },
        setMarkSheetFormLevel(state, value){
            state.markSheetFormLevel = value
        },
        setEditViewTermReportsOverlay(state, value){
            state.editViewTermReportsOverlay = value
        },
        setEditViewTermReportsLoading(state, value){
            state.editViewTermReportsLoading = value
        },
        setEditViewTermReportsExpand(state, value){
            state.editViewTermReportsExpand = value
        },
        setFormClasses(state, value){
            state.formClasses = value
        },
        setFormClassesList(state, value){
            state.formClassesList = value
        },
        setSelectedFormClass(state, value){
            state.selectedFormClass = value
        },
        setSelectedStudentRecord(state, value){
            state.selectedStudentRecord = value
        },
        setPagination(state, value){
            state.pagination = value
        },
        setCommentSheet(state, value){
            state.commentSheet = value
        },
        setFormTeacherComments(state, value){
            state.formTeacherComments = value
        },
        setEditViewTermReportsTable2Records(state, value){
            state.editViewTermReportsTable2Records = value
        },
        setEditViewTermReportsStudentSubjects(state, value){
            state.editViewTermReportsStudentSubjects = value
        },
        setUpdatedTable1Record(state, value){
            state.updatedTable1Record = value
        },
        setEditViewTermReportsSaveStatus(state, value){
            state.editViewTermReportsSaveStatus = value
        },
        setEditViewTermReportsSaving(state, value){
            state.editViewTermReportsSaving = value
        },
        setEditViewTermReportsSaveError(state, value){
            state.editViewTermReportsSaveError = value
        },
        setEditViewTermReportsSaved(state,value){
            state.editViewTermReportsSaved = value
        },
        setFormTeacherClass(state, value){
            state.formTeacherClass = value
        },
        setProgressOverlay(state, value){
            state.progressOverlay = value
        },
        setBottomSheetModel(state, value){
            state.bottomSheetModel = value
        },
        setBottomSheetTitle(state, value){
            state.bottomSheetTitle = value
        },
        setTable1Records(state, value){
            state.table1Records = value
        },

        setExpandTerms (state, value) {
            state.expandTerms = value
        },

        setTermsAvailable (state, value) {
            state.termsAvailable = value
        },        

        setDialogReport (state, value) {
            state.dialogReport = value
        },

        setSrc (state, value) {
            state.src = value
        },

        setOverlayReport (state, value) {
            state.overlayReport = value
        },

        setTermConfiguration (state, value) {
            state.termConfiguration = value
        },

        setPrintViewReports (state, value) {
            state.printViewReports = value
        }
    },
    actions: {
        async getLessons({ commit }, id){
            let url = '/api/teacher-lessons/' + id;
            try {
                let response = await axios.get(url);
                commit('setLessons', response.data);
                //commit('setExpandLessonOptions', true);                           
            } catch (error) {
                console.log(error)
            }
        },
        async getCurrentAcademicPeriod({ commit }){
            let url = '/api/current-period';
            try {
                const { data } = await axios.get(url);
                // console.log(data);
                const { current_term } = data;
                // console.log(current_term);               
                let academicYear = current_term.academic_year_id;
                commit('setAcademicYearId', academicYear);
                commit('setAcademicYear', academicYear.toString().substr(0, 4) + '-' + academicYear.toString().substr(4))
                commit('setCurrentYear', academicYear.toString().substr(0,4));
                commit('setCurrentTerm', current_term.term);
            } catch (error) {
                console.log(error);
            }
        },
        async getStudents({ commit, getters, dispatch }, id){
            //console.log('getting students..')
            let url = '/api/teacher-lesson-students/' 
            + getters.getLessonSelected.className + '/' 
            + getters.getCurrentYear + '/'
            + getters.getCurrentTerm + '/'
            + getters.getLessonSelected.subjectCode + '/'
            + id; 

            try {
                const { data: { data, total, registered, entered } } = await axios.get(url);
                const records = data.map((value, index) => {
                    value.count = index + 1;
                    return value;
                })
                commit('setTable2Records', records);
                await dispatch('getCodedCommentsList');
                commit('setMarkSheetLoading', false);
                commit('setMarkSheetOverlay', false);
                commit('setMainMenuButton', false);
                commit('setSubjectStudentsTotal', total);
                commit('setSubjectStudentsRegistered', registered);
                commit('setSubjectStudentsMarksEntered', entered);
            } catch (error) {
                console.log(error);
            }
        },
        async getCodedCommentsList({ commit }){
            let url = '/api/coded-comments';
            try {
                const { data } = await axios.get(url);
                commit('setCodedComments', data);
                //console.log(response);
            } catch (error) {
                console.log(error);
            }
        },

        saveTable2Record({ getters }){
            // console.log(getters.getUpdatedTable2Record);
            const url = '/api/table2'
            return axios.post(url, getters.getUpdatedTable2Record);
        },

        getTable1Record({ getters }, page_url){
            //let vm = this;
            let url = '/api/students-registered/'
            + getters.getCurrentYear + '/'
            + getters.getCurrentTerm + '/'
            + getters.getSelectedFormClass;

            page_url = page_url || url;

            return axios.get(page_url);
           
        },
        
        getTable2Records({ getters }){
            let url = '/api/student-table2-records/'
            + getters.getSelectedStudentRecord.student_id + '/'
            + getters.getCurrentYear + '/'
            + getters.getCurrentTerm + '/'
            + getters.getSelectedStudentRecord.class_id;
            return axios.get(url);
        },

        getFormClasses(){
            let url = '/api/form-classes';
            try{
                return axios.get(url);
            } catch (error) {
                console.log(error);
                this.setErrorResponse(error);
            }
        },

        setErrorResponse({ commit },error){
            console.log(error.response);
            if(error.response){                
                commit('setModalTitle', 'Error Occured.');
                commit('setModalIcon', 'error');
                commit('setModalMessage', error.response.data.message);
                commit('setModal', true);
            }
            else{
                commit('setModalTitle', 'Error Occured.');
                commit('setModalIcon', 'error');
                commit('setModalMessage', 'An Error Occured Please contact your administrator');
                commit('setModal', true);
            }
        },
       
        async postTable1Record({ getters }){
            let url = "/api/table1";
            console.log(getters.getSelectedStudentRecord);
            return axios.post(url, getters.getSelectedStudentRecord);
        },

        getAssignedFormTeacherClass({ getters }, id){
            let url = '/api/form-teacher-class/' + id 
            + '/' + getters.getAcademicYearId;
            //console.log(url);
            return axios.get(url);
        },

        getSubjectMarksEntered ({ getters }) {
            const url = "/api/student-subjects-entered/" +
            getters.getSelectedStudentRecord.year + "/" +
            getters.getSelectedStudentRecord.term + "/" + 
            getters.getSelectedStudentRecord.class_id + "/" +
            getters.getSelectedStudentRecord.student_id;
            return axios.get(url);
        },

        getTermConfiguration({ getters }, formClass) {
            const url = "/api/term-configuration/" +
            getters.getCurrentYear + "/" +
            getters.getCurrentTerm + "/" +
            formClass;
            return axios.get(url); 
        }
        
    }
}    