<template>
    <v-btn        
        text
        v-on:click="logOut"
      >
        <span class="mr-2 primary--text">Logout</span>
        <v-icon
            color="primary"
        >
            mdi-logout
        </v-icon>
      </v-btn>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    props: {
        component: String,
    },   
    
    methods: {
        ...mapActions({
            signOut: 'auth/signOut'
        }),

        ...mapMutations({
            setOverlay: 'termReports/setProgressOverlay',
            setOverlayAdmin: 'admin/setProgressOverlay',
            setStep: 'auth/setStep',
            setExpand: 'auth/setExpand',            
        }),

        async logOut () {
            if(this.component == 'admin') this.setOverlayAdmin(true);
            else this.setOverlay(true);
            this.setStep(1);            
            await this.signOut();
            this.setExpand(true);
            if(this.component == 'admin') this.setOverlayAdmin(true);
            else this.setOverlay(true);            
            //console.log("logout complete");
            this.$router.replace('/')
        }

        
    }
}
</script>