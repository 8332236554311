import axios from 'axios'

export default{
    namespaced: true,

    state: {
        classSelected: null,
    },

    getters: {
        getClassSelected (state) {
            return state.classSelected
        }
    },

    mutations: {
        setClassSelected (state, classSelected) {
            state.classSelected = classSelected
        }
    },

    actions: {
        getFormClasses () {
            const url = '/api/class-list-classes';
            return axios.get(url);
        },

        downloadClassList ({ getters }) {
            return axios({
                method: 'get',
                url: '/api/class-list-spreadsheet?class_id=' + getters.getClassSelected,
                responseType: 'arraybuffer',
            });
        }

    }
}