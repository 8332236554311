<template>
    <v-row
        justify="center"
    >
        <v-col>
            <v-card
                width="70"
                flat
                class="d-flex flex-column align-center"
                v-show="mainMenuButton"
            >
                <v-expand-transition>
                    <v-btn
                        :color="buttonColor"
                        dark
                        fab
                        small
                        @click="showMainMenu"    
                    >
                        <v-icon>
                            mdi-arrow-left
                        </v-icon>
                        
                    </v-btn>
                </v-expand-transition>
                <div
                    class="caption mt-2"
                >
                    Main Menu
                </div>
            </v-card>
        </v-col>
        <v-col>        
            <v-card
                class="mx-auto pa-0"
                width="920"   
                flat            
            >
                <v-card-title
                    class="d-flex primary--text  py-2 px-0"
                >
                    <v-spacer></v-spacer>
                    <div class="text-h6 font-weight-bold  black--text">Mark Sheet</div>
                    <v-spacer></v-spacer>
                    <v-btn
                        text                
                        x-small
                        color="primary"
                        @click="closeMarkSheet"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>            
                </v-card-title>
                <v-divider></v-divider>
                <v-row class="px-4">
                    <v-col class="py-0">
                        <Header/>
                        <v-divider></v-divider>
                        <MarkSheet/>
                        <v-row class="mt-3 mr-4">
                            <v-spacer></v-spacer>
                            <div class="caption">
                                Total Students: {{ totalStudents }}
                            </div>
                        </v-row>
                    </v-col>
                </v-row>
                
                <v-overlay
                    :absolute="absolute"
                    :value="overlay"                    
                    color="grey lighten-5"
                    opacity="0.9"
                    class="pa-5"
                >                    
                    <Lesson/> 
                        
                    <v-progress-circular 
                        transition="fade-transition" 
                        indeterminate 
                        size="64"
                        v-show="loading"
                        color="blue"
                    ></v-progress-circular> 
                </v-overlay>
               
            </v-card>
        </v-col>
        <v-col>
            
        </v-col>
    </v-row>    
</template>

<script>
import Header from './EnterMarksHeader'
import Lesson from './EnterMarksLesson'
import MarkSheet from './EnterMarksSheet'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'EnterMarks',
    components: {
        Header,
        Lesson,
        MarkSheet,
    },
    computed: {
        ...mapGetters({
            overlay: 'termReports/getMarkSheetOverlay',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            mainMenuButton: 'termReports/getMainMenuButton',
            loading: 'termReports/getMarkSheetLoading',
            totalStudents: 'termReports/getSubjectStudentsRegistered',   
        }),
        themeColor(){          
            return 'primary';
        },
        buttonColor(){
            return 'primary';
        }
    },
    data: () => ({
        absolute: true,             
        userTimetable: [],
    }),
    methods: {
        ...mapMutations({
            mainMenu: 'termReports/setMainMenu',
            enterMarks: 'termReports/setEnterMarks',
            setOverlay: 'termReports/setMarkSheetOverlay',
            expand: 'termReports/setExpandLessonOptions',
            welcomeMessage: 'termReports/setWelcomeMessage',
            table2Records: 'termReports/setTable2Records',
            setStudentName: 'termReports/setMarkSheetStudentSelected',
        }),
        setLessonSelected(e){
            this.loading = true;
            console.log(e);
        },
        showMainMenu(){
            console.log('closing..');            
            this.table2Records([]);
            this.enterMarks(false);
            this.setOverlay(true);
            this.expand(false);
            this.welcomeMessage(true);
            this.mainMenu(true);
        },
        closeMarkSheet(){
            this.setOverlay(true);
            this.table2Records([]);
            this.setStudentName('');
            this.expand(true);
            
        }
    }
}
</script>