<template>
    <layout>       

        <template v-slot:username>
            <v-card-text>                                                
                <div class="primary--text text-h5 text-center">
                    Term Reports Sign in                                                            
                </div>                                            
            </v-card-text>
            <v-form 
                class="pa-5"
                @submit.prevent
            >
                <v-text-field
                label="Email"
                placeholder="Enter email"
                name="id"                                                
                type="text"
                :color="icon_color"
                v-model="formData.name"
                clearable
                autofocus
                v-on:keyup="keyPress"
                >
                    <v-icon
                        slot="prepend"
                        :color="icon_color"
                    >
                        mdi-account
                    </v-icon>
                </v-text-field>
                <div class="text-right mt-4 mb-4">                                                
                    <v-btn 
                        color="primary" 
                        @click="nextStep" 
                        dark
                        block
                    >
                        NEXT
                    </v-btn>
                </div>
            </v-form>
        </template>

        <template v-slot:password>
            <v-form 
                class="pa-5"
                @submit.prevent="submit"
            >
                <v-text-field
                    v-show="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :label="passwordLabel"
                    :placeholder="passwordPlaceholder"
                    :hint="passwordHint"
                    persistent-hint
                    @click:append="show = !show"                                                    
                    name="password"
                    v-model="formData.password"
                    clearable
                    autofocus
                >
                    <v-icon
                        slot="prepend"
                        :color="icon_color"
                    >
                        mdi-key
                    </v-icon>
                </v-text-field>
            

                <div class="text-right mt-4  mb-4">
                    <v-btn 
                        color="primary" 
                        @click="submit" 
                        dark
                        block
                    >
                        LOGIN
                    </v-btn>
                </div>
            </v-form>             
        </template>

    </layout>
</template>

<script>
import Layout from '../layouts/Login';
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    name: 'LoginTermReports',
    components: {
        Layout
    },
    data: () => ({
        formData: {
            name: '',
            password: '',            
        },
        show: false,        
        passwordPlaceholder: 'Enter password',
        passwordLabel: 'Password',
        passwordHint: '',
        password: true,
    }),
    computed: {
        ...mapGetters({            
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            step: 'auth/getStep',
            authenticated: 'auth/getAuthenticated',
            resetPassword: 'auth/getResetPassword',                        
        }),        
        icon_color(){
             return 'primary';
        },
        title_css(){
            return  'primary--text text-h5 text-left ';
        },
    },
    methods: {
        ...mapActions({
            signIn: 'auth/employeeSignIn',
            getUser: 'auth/user',          
        }),
        ...mapMutations({
            loginError: 'auth/setLoginErrors', 
            setStep: 'auth/setStep',
            setOverlay: 'auth/setOverlay',
            setUserName: 'auth/setUserName',
            setResetPassword: 'auth/setResetPassword',
            setExpand: 'auth/setExpand',          
        }),

        async submit () {            
            console.log('Authenticating...');
            await this.signIn(this.formData);            
            if(this.authenticated){
                console.log('User Authenticated.'); 
                if(this.resetPassword){
                    setTimeout(() => {this.setOverlay(false)});
                    this.setStep(3);
                }
                else{
                    this.setExpand(false);
                    // await this.employee();                    
                    setTimeout(() => {this.setOverlay(false)})
                    this.setOverlay(false);
                    this.$router.replace('/term-reports');
                }
                
            }
            else{
                console.log('Not authenticated');
            }

        },
        async nextStep () {
            this.setOverlay(true);
            console.log('next step');            
            this.setUserName(this.formData.name);
            try {
                let response = await this.getUser();
                this.setOverlay(false);
                this.loginError('');
                if(response.data.password_reset == 1){
                    this.setResetPassword(true);
                    this.setStep(2);
                }
                else{
                    this.setResetPassword(false);
                    this.setStep(2);
                }
                //console.log(response);
            } catch (error) {
                this.setOverlay(false);
                if(error.response.statusText){
                    this.loginError(error.response.statusText);
                }
                else{
                    this.loginError(`An Error has occured ( ${error.status})`)
                }
                console.log(error.response.statusText);
            }            
           
        },
        keyPress (e) {
            if(e.keyCode === 13){
                this.nextStep();
            }
        },
        
    },
}
</script>