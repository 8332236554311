<template>
    <tr ref="row" class="fade-row" @click="recordSelected">
        <td>{{ markRecord.count }}</td>
        <td class="pt-1">
            {{ markRecord.name }}
        </td>        
        <td >
            <v-text-field
                v-model="markRecord.course_mark"
                outlined
                dense
                :disabled="assesmentCourse.disable"
                :filled="assesmentCourse.disable" 
                hide-details
                @blur="recordChanged"
                @keypress="numberValidation"
                class="mt-2"    
                type="number"
                min=0
                max=100            
            ></v-text-field>
            <v-tooltip 
                top               
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn                       
                        @click="setAttendance(assesmentCourse, true)"
                        block
                        height="20"
                        class="my-2 primary--text"                       
                        depressed                                              
                        v-bind="attrs"
                        v-on="on"
                    >{{ assesmentCourse.button }}</v-btn>
                </template>
                <span>{{ assesmentCourse.tooltip }}</span>
            </v-tooltip>   
        </td>        
        <td>
            <v-text-field
                v-model="markRecord.exam_mark"
                outlined
                dense
                hide-details
                @blur="recordChanged"
                @keypress="numberValidation"
                :disabled="assesmentExam.disable"
                :filled="assesmentExam.disable" 
                class="mt-2"
                type="number"
                min=0
                max=100
            ></v-text-field>
            <v-tooltip 
                top               
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn                        
                        @click="setAttendance(assesmentExam, true)"
                        block
                        height="20"
                        class="my-2 primary--text"                        
                        depressed                                                
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ assesmentExam.button }}
                    </v-btn>
                </template>
                <span>{{ assesmentExam.tooltip }}</span>
            </v-tooltip> 
        </td>
        
        <td>
            <v-autocomplete
                v-model="markRecord.coded_comment"                
                :items="comments1"
                item-value="id"
                item-text="comment"
                outlined
                dense
                hide-details
                chips
                @change="recordChanged"
                class="mt-2" 
                :filter="customFilter" 
            >
                <template v-slot:selection="{item}">
                    <v-chip
                        close
                        small
                        @click:close="remove(item, 'coded_comment')"
                    >
                        {{ item.id }}
                    </v-chip>
                </template>


                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template>
            </v-autocomplete >
            <v-btn
               disabled
               block
               height="20"
               class="my-2"
               text
            > </v-btn>
        </td>            
        <td>
            <v-autocomplete
                v-model="markRecord.coded_comment_1"                
                :items="comments2"
                item-value="id"
                item-text="comment"
                outlined
                dense
                hide-details
                chips
                @change="recordChanged"
                class="mt-2" 
                :filter="customFilter" 
            >
                <template v-slot:selection="{item}">
                    <v-chip
                        close
                        small
                        @click:close="remove(item, 'coded_comment_1')"
                    >
                        {{ item.id }}
                    </v-chip>
                </template>


                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template>
            </v-autocomplete >
            <v-btn
               disabled
               block
               height="20"
               class="my-2"
               text
            > </v-btn>
        </td>
        <td>
            <v-tooltip 
                bottom
                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="primary"                        
                        v-bind="attrs"
                        v-on="on"
                        v-show="saved"
                        class="mr-2"
                    >
                        mdi-check-all
                    </v-icon>
                </template>    
                <span>Saved</span>                
            </v-tooltip>
            <v-tooltip 
                top
                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="red"                       
                        v-bind="attrs"
                        v-on="on"
                        v-show="error"
                    >
                        mdi-alert-circle
                    </v-icon>
                </template>    
                <span>Error</span>               
            </v-tooltip>
            <v-progress-circular
                indeterminate
                color="primary"
                v-show="saving"
                size="16"
                width="3"
            ></v-progress-circular>
            <v-btn
               disabled
               block
               height="20"
               class="my-2"
               text
            > </v-btn>
        </td>
    </tr>

    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props:{
        studentRecord: Object, 
    },

    created () {
        this.initialize();
    },

    watch: {        
        codedComments: {
            deep: true,
            handler(value){
                if(value.length != 0){
                    this.setComments();
                }
            }
        },
        // codedComment: {
        //     handler(value){
        //         //console.log(value);
        //         this.markRecord.coded_comment = value.id;
        //     }
        // },
        // codedComment1: {
        //     handler(value){
        //         //console.log(value);
        //         this.markRecord.coded_comment_1 = value.id;
        //     }
        // },
       
    },       
    data() {
        return {
            comments1: [],
            comments2: [],            
            markRecord: {},
            codedComment: { id: null, comment: null },
            codedComment1: { id: null, comment: null },            
            saved: false,
            saving: false,
            error: false,
            assesmentCourse: {
                attendance: 1,
                button: 'P',
                tooltip: 'Present',
                disable: false,
                modelAssesment: 'course_mark',
                modelAttendance: 'course_attendance',
                test: 2,
            },
            assesmentExam: {
                attendance: 1,
                button: 'P',
                tooltip: 'Present',
                disable: false,
                modelAssesment: 'exam_mark',
                modelAttendance: 'exam_attendance',
                test: 1,
            },
            rowSelected: null,           
        }
    },
    computed: {
        ...mapGetters({
            codedComments: 'termReports/getCodedComments',
            employeeId: 'auth/getEmployeeId',
            marksEntered: 'termReports/getSubjectStudentsMarksEntered',
            lessonSelected: 'termReports/getLessonSelected',
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm', 
        }),
          
    },
    methods : {
        ...mapMutations({
            setUpdatedRecord: 'termReports/setUpdatedTable2Record',
            setStudentSelected: 'termReports/setMarkSheetStudentSelected',
            setMarkSheetEntered: 'termReports/setSubjectStudentsMarksEntered',
        }),

        ...mapActions({
            save: 'termReports/saveTable2Record',
        }),
        
        initialize(){
            this.markRecord = {...this.studentRecord};
            // this.savedDataCheck();
            this.setComments();
            this.initializeAttendance(this.assesmentCourse, this.markRecord.course_attendance);
            this.initializeAttendance(this.assesmentExam, this.markRecord.exam_attendance);  
        },

        initializeAttendance(assesment, attendance){
            assesment.attendance = attendance;
            switch(attendance){
                case 0:                    
                    assesment.button = 'Abs';
                    assesment.tooltip = 'Absent';
                    assesment.disable = true;
                    break;  
                case 1:                    
                    assesment.button = 'P';
                    assesment.tooltip = 'Present';
                    assesment.disable = false;
                    break;
            }
        },     

        async recordChanged () {
            if(this.recordChangeCheck()){
                this.animation();
                this.saving = true;
                this.saved = false;
                this.error = false;
                this.setUpdatedRecord(this.markRecord);
                try {
                    await this.save();
                    this.saving = false;
                    this.saved = true;
                    this.error = false;
                } catch (error) {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                    this.saving = false;
                    this.error = true;
                    this.saved = false;
                }               
            }
            else{
                console.log('record blank');
            }                  
        },

        recordChangeCheck () {
            let change = false;
            // let shouldContinue = true;
            console.log(this.studentRecord);
            console.log(this.markRecord)
            Object.keys(this.studentRecord).forEach(key => {
                // if(!shouldContinue) return;
                if(change) return;
                if(
                    key === 'course_mark' ||
                    key === 'exam_mark' ||
                    key === 'coded_comment' ||
                    key === 'coded_comment_1' ||
                    key === 'course_attendance' ||
                    key === 'exam_attendance'
                ){
                    change = this.studentRecord[key] !== this.markRecord[key]
                    // if(change) shouldContinue = false;
                }

            })
            return change;
        },

        savedDataCheck () {
            if(this.recordChangeCheck()) this.saved = true;
        },

        recordSelected () {
            let records = this.table2Records;
            // let entered = 0;
            records.forEach(record => {
                if(record.course_mark || record.exam_mark || record.conduct || record.application || record.preparedness || record.coded_comment || record.coded_comment_1){
                    // entered++;
                }
            })
            this.setStudentSelected(this.markRecord.name);
        },

        animation () {
            const row = this.$refs.row;
            row.classList.add("active")
            setTimeout(function() {
                row.classList.remove("active")
                row.classList.add("complete");
                setTimeout(function() {
                    row.classList.remove("complete")
                }, 1000)
            }, 1000)
        },
                
        setComments () { 
            // this.comments1.push({id: null, comment: null});  
            // this.comments2.push({id: null, comment: null});
            this.codedComments.forEach(record =>{
                this.comments1.push({ id: parseInt(record.id), comment: record.detail });
                this.comments2.push({ id: parseInt(record.id), comment: record.detail });
            });           
            
            if(this.studentRecord.coded_comment){
                this.codedComment = { id: parseInt(this.studentRecord.coded_comment) };
            } 
            if(this.studentRecord.coded_comment_1){
                this.codedComment1 = { id: parseInt(this.studentRecord.coded_comment_1) };
            }
            
        },            

        setAttendance (assesment, update = false) {
            switch(assesment.attendance){
                case 0:                    
                    assesment.attendance = 1;
                    assesment.button = 'P';
                    assesment.tooltip = 'Present';
                    this.markRecord[assesment.modelAttendance] = 1;
                    assesment.disable = false;                    
                    break;    
                case 1:
                    assesment.attendance = 0;
                    assesment.button = 'Abs';
                    assesment.tooltip = 'Absent';
                    this.markRecord[assesment.modelAssesment] = null;
                    this.markRecord[assesment.modelAttendance] = 0
                    assesment.disable = true;
                    break;
                           
            }
            if(update) this.recordChanged();
        },

        numberValidation ($event) {
            if($event.keyCode < 48 || $event.keyCode > 57)
            return $event.preventDefault();
        },

        customFilter (item, queryText) {
            const itemValue = parseFloat(item.id);
            const itemText = item.comment.toLowerCase();
            const queryValue = parseFloat(queryText);
            const queryTextLower = queryText.toLowerCase();
            return itemValue === queryValue || itemText.indexOf(queryTextLower) !== -1;
        },

        remove (item, model) {
            console.log(item)
            this.markRecord[model] = null;
            this.recordChanged();
        }
    }
}
</script>

<style scoped>
    ::v-deep .v-text-field__slot input{
        text-align: center;
    }

    ::v-deep .v-autocomplete.v-select.v-input--is-focused input{
        min-width: 0;
    }

    .fade-row {
    transition: background-color 1s ease-in-out;
    }

    .fade-row.active {
    background-color: #A5D2F1 !important;
    transition: background-color 1s ease-in-out;
    }

    .fade-row.complete {
    background-color: #ffffff;
    transition: background-color 1s ease-in-out;
    }



</style>

