<template>
    <v-container fluid>
        <v-row
            justify="center"
        >
            <v-col>
                <v-card
                    width="70"
                    flat
                    class="d-flex flex-column align-center grey lighten-5"
                    v-show="mainMenuButton"
                >
                    <v-expand-transition>
                        <v-btn
                            :color="buttonColor"
                            dark
                            fab
                            small
                            @click="showMainMenu"    
                        >
                            <v-icon>
                                mdi-arrow-left
                            </v-icon>
                            
                        </v-btn>
                    </v-expand-transition>
                    <div
                        class="caption mt-2"
                    >
                        Main Menu
                    </div>
                </v-card>
            </v-col>

            <v-col>
                <v-card
                    class="pa-0"
                    width="1100"
                    height="530"
                >
                    <v-overlay
                        absolute
                        :value="overlay"                    
                        color="grey lighten-5"
                        opacity="0.9"                    
                    >                    
                        <v-expand-transition>
                            
                            <v-card
                                max-width="300"
                                class="mx-auto"
                                transition="scroll-y-transition"
                                v-show="expand"
                                light 
                            >
                                <v-toolbar
                                    :color="themeColor"
                                    dark
                                >
                                    <v-toolbar-title>Select Class</v-toolbar-title>
                                </v-toolbar>
                                <div style="max-height: 400px; width: 250px; overflow-y: auto">    
                                <v-list light >
                                    <v-list-group
                                        v-for="item in formClasses"
                                        :key = "item.title"
                                        v-model="item.active"
                                        :prepend-icon="item.action"
                                        no-action
                                    >
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.title }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <v-list-item
                                            v-for="subItem in item.items"
                                            :key="subItem.title"
                                            @click="setSelectedClass(subItem.title)"                                       
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ subItem.title }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-group>
                                </v-list>
                                </div>                     
                            </v-card>
                            
                        </v-expand-transition>

                        <v-expand-transition>
                            <v-card
                                max-width="300"
                                class="mx-auto"
                                transition="scroll-y-transition"
                                v-show="expandTerms"
                                light 
                            >
                                <v-toolbar
                                    :color="themeColor"
                                    dark
                                >
                                    <v-toolbar-title>Select Report Term</v-toolbar-title>
                                </v-toolbar>
                                <v-list light >
                                    <v-list-item-group>
                                        <v-list-item
                                            v-for="(item, i) in termsAvailable"
                                            :key="i"
                                            @click="setSelectedTerm(item.term)"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>Term {{ item. term}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>    
                                </v-list>    
                            </v-card>
                        </v-expand-transition>

                        <v-progress-circular 
                            transition="fade-transition" 
                            indeterminate 
                            size="64"
                            v-show="loading"
                            color="blue"
                        ></v-progress-circular>

                    </v-overlay>
                    
                    <v-card-title 
                        :class="cardTitleClass"
                    >
                        <v-spacer></v-spacer>
                        <div 
                            style="letter-spacing: 1px; text-transform: uppercase; font-weight: bold"
                        >Edit / View Term Reports</div>
                        <v-spacer></v-spacer>
                        <v-btn
                            text                
                            x-small
                            color="white"
                            @click="closeEditViewTermReports"
                        >
                            <v-icon>mdi-window-close</v-icon>
                        </v-btn>                    
                    </v-card-title>
                    
                    <v-container fluid class="pt-0 ml-0 mr-0">
                        <v-row>
                            <student-details></student-details>
                            <subject-records></subject-records>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col>
                
            </v-col>
        </v-row>

        <v-dialog
            v-model="dialogReport"
            max-width="90vw"
            persistent            
        >
            <v-card height="80vh">
                <iframe 
                    ref="pdf" 
                    style="width:100%; height:100%" 
                    frameBorder="0" 
                    :src="src"
                ></iframe>
                <v-overlay
                    absolute
                    :value="overlayReport"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>                
            </v-card>
            <v-container
                fluid
                class="pa-0"
            >
                <v-sheet>
                    <v-row
                        justify="end"
                        class="ma-0"
                    >
                        <v-btn
                            color="primary"
                            small
                            dark
                            @click="closeReport"
                            class="my-2 mr-4"
                        >
                            Close
                        </v-btn>
                    </v-row>
                </v-sheet>
            </v-container>
        </v-dialog>

        <v-bottom-sheet 
            v-model="sheet" 
            inset
            persistent
        >
            <v-row align="center" justify="center">
                <v-alert
                    :value="error"
                    prominent
                    type="error"
                    transition="scale-transition"
                    dense                        
                >
                    <v-row align="center">
                        <v-col>{{ errorMessage }}</v-col>
                    </v-row>
                </v-alert>
            </v-row>

            <v-sheet class="text-center px-10" height="200px">
                <v-row justify="end">
                    <div>
                        <v-btn
                            class="mt-1"
                            text
                            color="blue darken-3"
                            @click="closeBottomSheet"
                        >close</v-btn>
                    </div>
                </v-row>                
                <v-form ref="form">
                    <v-textarea                                       
                        :label="bottomSheetTitle"                        
                        hint="Max 215 Characters"
                        rows="3"                                           
                        counter                                             
                        no-resize
                        v-model="bottomSheetModel"
                        autofocus                        
                        persistent-hint
                        maxlength="215"                                                                                                                 
                    >                        
                    </v-textarea>
                </v-form>                    
            </v-sheet>
        </v-bottom-sheet>

    </v-container>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import StudentDetails from './EditViewTermReportsStudentDetails';
import SubjectRecords from './EditViewTermReportsSubjectRecords';
export default {
    components: {
        StudentDetails,
        SubjectRecords,
    },
    data: () => ({       
       error: false,
       errorMessage: '',       
       rules: [
            value => {
                if( value.length <= 215){
                    
                    return true;
                }
                else{
                    return 'Max 215 characters';
                }
            }
        ],
        bottomSheetModel: '',
        src: '',
        overlayReport: false,               
    }),
    computed: {
        ...mapGetters({
            mainMenuButton: 'termReports/getMainMenuButton',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            overlay: 'termReports/getEditViewTermReportsOverlay',
            loading: 'termReports/getEditViewTermReportsLoading',
            expand: 'termReports/getEditViewTermReportsExpand',
            formClasses: 'termReports/getFormClasses',
            studentRecord: 'termReports/getSelectedStudentRecord',
            sheet: 'termReports/getCommentSheet',
            model: 'termReports/getBottomSheetModel',
            bottomSheetTitle: 'termReports/getBottomSheetTitle',
            expandTerms: 'termReports/getExpandTerms',
            termsAvailable: 'termReports/getTermsAvailable',
            dialogReport: 'termReports/getDialogReport',
            srcObject: 'termReports/getSrc',
            selectedFormClass: 'termReports/getSelectedFormClass',
        }),
        buttonColor(){
            return 'primary';
        },
        themeColor(){          
            return 'primary';
        },
        cardTitleClass(){
            return 'text-subtitle-2 pa-2 white--text d-flex justify-center primary';
        },       
    },
    watch: {
        formClasses: {
            handler: function () {
                //console.log(val);                
                this.setLoading(false);
                this.setExpand(true);
                //console.log(`Loading: ${this.loading}`);
            }
        },

        model: {
            handler: function (val) {                
                this.bottomSheetModel = this.studentRecord[val];
            }
        },

        studentRecord: {    
            handler: function () {                
                this.bottomSheetModel = this.studentRecord[this.model];
            }
        },
       
    },
    
    methods: {
        ...mapMutations({
            setOverlay: 'termReports/setEditViewTermReportsOverlay',
            setLoading: 'termReports/setEditViewTermReportsLoading',
            setExpand: 'termReports/setEditViewTermReportsExpand',
            setSelectedFormClass: 'termReports/setSelectedFormClass',
            setSelectedStudentRecord: 'termReports/setSelectedStudentRecord',
            setPagination: 'termReports/setPagination',
            setCommentSheet: 'termReports/setCommentSheet',
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            editViewTermReports: 'termReports/setEditViewTermReports',
            welcomeMessage: 'termReports/setWelcomeMessage',
            mainMenu: 'termReports/setMainMenu',
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setTable1Records: 'termReports/setTable1Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects',
            setTermsAvailable: 'termReports/setTermsAvailable',           
            setExpandTerms: 'termReports/setExpandTerms',
            setDialogReport: 'termReports/setDialogReport',
            setTermConfiguration: 'termReports/setTermConfiguration',            
        }),
        ...mapActions({
            getTable1Record: 'termReports/getTable1Record',
            getTable2Records: 'termReports/getTable2Records',
            postTable1Record: 'termReports/postTable1Record',
            setErrorResponse: 'termReports/setErrorResponse',
            getCodedCommentsList: 'termReports/getCodedCommentsList',
            getTermConfiguration: 'termReports/getTermConfiguration',

        }),       
        showMainMenu(){
            //console.log('closing..');
            this.setExpand(false);
            this.editViewTermReports(false);            
            this.welcomeMessage(true);
            this.mainMenu(true); 
        },

        async setSelectedClass(formClass){
            const termConfigurationPromise = this.getTermConfiguration(formClass);
            this.setSelectedFormClass(formClass);
            console.log('setting student records...');
            const { data: dataTermConfiguration } = await termConfigurationPromise;
            this.setTermConfiguration(dataTermConfiguration);
            this.setStudentRecords();
            this.setExpand(false);
            setTimeout(() => {
                this.setLoading(true);
            }, 600)
        },

        async setStudentRecords () {
            try {
                const codedCommentsPromise = this.getCodedCommentsList();
                const table1RecordsPromise = this.getTable1Record();
               
                
                const { data: dataTable1 } = await table1RecordsPromise;
                this.makePagination(dataTable1);
                this.setTable1Records(dataTable1.data);
                if(dataTable1.data.length > 0){
                    this.setSelectedStudentRecord(dataTable1.data[0]);
                } 

                const { data: dataTable2 } = await this.getTable2Records();
                this.setTable2Records(dataTable2.table2_records);
                // console.log(dataTable2.student_subjects);
                this.setStudentSubjects(dataTable2.student_subjects);
                this.setTermsAvailable(dataTable2.terms);

                await codedCommentsPromise;
                this.setLoading(false);
                this.$nextTick(() => {
                    this.setOverlay(false);
                })   

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
                                
        },

        closeBottomSheet(){            
            this.setCommentSheet(false);
            this.updateRecord();
        },
        makePagination(data){
            let pagination = {
                current_page: 1,
                last_page: data.data.length,
                next_page: 2,
                prev_page: null,
            }
            
            this.setPagination(pagination);
        },
        closeEditViewTermReports(){
            console.log('closing...');
            this.setOverlay(true);
            this.setExpand(true);
        },
        async updateRecord(){
            console.log('updating...');
            this.setSaveStatus('Saving...');
            //console.log(this.bottomSheetModel);
            this.studentRecord[this.model] = this.bottomSheetModel;
            //this.bottomSheetModel = '';
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            //console.log(this.studentRecord);
            try{
                let response = await this.postTable1Record();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                console.log(response);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }
            
        },
        
        setSelectedTerm (term) { 
            this.srcObject.term = term;
            //console.log(this.srcObject);
            this.src = Object.values(this.srcObject).join("/");
            console.log(this.src);

            this.setExpandTerms(false);
            setTimeout(() => {
                this.setOverlay(false);
                this.openReport();
            }, 700);
        },

        openReport () {
            this.setDialogReport(true);
            this.overlayReport = true;
            this.$nextTick( function () {
                this.$refs.pdf.onload = () => {
                    console.log('report loaded');
                    this.overlayReport = false
                }
            })
        },

        closeReport () {
            this.setDialogReport(false);
            this.src = null;
        }
    }

}
</script>

<style scoped>
    ::v-deep .v-text-field__slot textarea{
        font-size: 14px;        
    }
</style>