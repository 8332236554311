import axios from 'axios'

export default{
    namespaced: true,

    state: {
        //
    },

    getters: {
        //
    },

    mutations: {
        //
    },

    actions: {
        getSubjects () {
            const url = '/api/subjects';
            return axios.get(url);
        },

        getDeanAssignment ({ rootGetters }) {
            const url = '/api/dean-assignment/' + rootGetters['auth/getEmployeeId'];
            return axios.get(url);
        }
    }
}