<template>
    <v-col class="pr-0 pb-0">
        <v-row class="mx-3">
            <v-card
                flat
                width="300"
                align="center"
                justify="center"
                
               
            >
                <span 
                    class="mr-3 text-subtitle-1 green--text"                                         
                > {{ studentName }}</span>                                 
                <span 
                    class="text-subtitle-1 green--text"
                >{{ classId }}</span>
            </v-card>
        </v-row>

        <v-row class="mx-3">
            <v-card                                    
                width="300"
                flat
                                                  
            >
                <v-row
                    class="px-3"
                >
                    <v-col
                        align="center"
                        justify="center"
                        v-for="item in academicPeriod"
                        :key="item.title"
                        class="pa-0"
                    >
                        <div 
                            class="text-caption blue--text"
                        >{{item.title}}</div>
                        <div 
                            class="text-caption green--text"
                        >{{item.value}}</div>
                    </v-col>
                </v-row>
            </v-card>                                 
        </v-row>

        <v-row class="mx-3">
            <v-card                
                width="312"
                flat
            >
                <div>
                    <v-list class="pb-0" dense>
                        <v-list-item
                            v-for="item in recordSummary"
                            :key="item.title"
                            class="pl-0"
                        >
                            <v-list-item-content>
                                <v-list-item-title 
                                    class="text-caption"
                                >
                                    {{ item.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-card                               
                                width="120"                                                                                                       
                                flat
                            >
                                <v-text-field
                                    v-if="!item.select"
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled" 
                                    @blur="updateRecord"
                                    dense
                                    hide-details
                                    v-model="studentRecord[item.model]"
                                    height="25"
                                    class="text-caption pa-0"
                                    type="number"
                                    min="0"
                                    @keypress="absValue"
                                    
                                ></v-text-field>

                                <v-select
                                    v-if="item.select"
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled"
                                    :items="grades2"                                    
                                    v-model="studentRecord[item.model]"
                                    hide-details                                    
                                    outlined
                                    class="text-caption text-center"
                                    @change="updateRecord"
                                >
                                   
                                </v-select>
                            </v-card> 
                        </v-list-item>
                    </v-list>
                </div>                                     
            </v-card>
        </v-row>

         <v-row 
            justify="space-between"
            class="my-3 mx-3 pr-3"
        >
            <v-btn 
                class="text-caption pa-2  white--text"
                width="15"
                height="30"                
                color="primary"
                @click="previousRecord"
                :disabled="!pagination.prev_page"                
            >
                <div class="d-flex flex-column"> 
                    <span>PREV</span>
                </div>                                         
            </v-btn>
            
            <v-btn 
                class="text-caption pa-2"
                width="15"
                height="30"
                outlined
                disabled
                color="primary"                
            >
                <span class="blue--text">{{pagination.current_page}} of {{ pagination.last_page}}</span>                                                        
            </v-btn>
            
            <v-btn 
                class="text-caption pa-2  white--text"
                width="15"
                height="30"                
                color="primary"
                @click="nextRecord"
                :disabled="!pagination.next_page"
            >
                <div class="d-flex flex-column">
                    <span>NEXT</span>
                </div>                                         
            </v-btn>
        </v-row>

        

        <v-row 
            justify="space-between"
            class="mt-6 mb-3 mx-3 pr-3"
        >
            <v-card flat class="mr-2">         
                <v-btn                    
                    outlined
                    color="primary"                       
                    @click="searchStudent"
                    small
                    height="40px"
                    class="px-2"                                      
                >
                    <div class="d-flex flex-column text-caption">
                        <span style="font-size:0.7rem">Search</span>
                        <span style="font-size:0.7rem">Students</span>
                    </div>
                </v-btn>
            </v-card>

            <v-card flat class="mr-2">         
                <v-btn                    
                    outlined
                    color="primary"
                    small
                    height="40px"
                    class="px-2"
                    @click="nextClass"                         
                >
                    <div class="d-flex flex-column text-caption">
                        <span style="font-size:0.7rem">Next</span>
                        <span style="font-size:0.7rem">Class</span>
                    </div>
                </v-btn>
            </v-card>

            <v-card flat class="mr-2">         
                <v-btn                   
                    outlined
                    color="primary"
                    small
                    height="40px"
                    class="px-2"
                    @click="printOneReport"                         
                >
                    <div class="d-flex flex-column text-caption">
                        <span style="font-size:0.7rem">Print</span>
                        <span style="font-size:0.7rem">One</span>
                    </div>
                </v-btn>
            </v-card>

            <v-card flat class="pt-0">         
                <v-btn                    
                    outlined
                    color="primary"
                    small
                    height="40px"
                    class="px-2"
                    @click="printClass"                         
                >
                    <div class="d-flex flex-column text-caption">
                        <span style="font-size:0.7rem">Print</span>
                        <span style="font-size:0.7rem">Class</span>
                    </div>
                </v-btn>
            </v-card>            
        </v-row>       

        <v-overlay
            absolute
            :value="overlay"
            color="secondary"
            opacity="0.6"
        >
            <v-expand-transition>
                <v-card
                    max-width="600"
                    class="mx-auto pa-6"
                    transition="scroll-y-transition"
                    v-show="expand"
                    light
                >
                    <v-card-title
                        class="mb-6"
                    >
                        <v-text-field
                            v-model="search"
                            label="Search for Student Record"
                            append-icon="mdi-magnify"
                            class="mx-4"
                            hide-details
                            single-line
                            clearable
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="table1Records"
                        :search="search"
                        fixed-header
                        height="40vh"
                        :options="options"
                        dense
                    >
                        <template
                            v-slot:[`item.actions`]="{ item }"
                        >
                            <v-btn
                                text
                                color="primary"
                                @click="viewRecord(item)"
                            >
                                View Record
                            </v-btn>
                        </template>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            outlined
                            @click="cancelSearch"
                        >
                            Cancel Search
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
        </v-overlay>


    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    created(){
        this.initialize();
    },
    computed: {
        ...mapGetters({
            studentRecord: 'termReports/getSelectedStudentRecord',            
            academicYear: 'termReports/getAcademicYear',
            currentTerm: 'termReports/getCurrentTerm',
            currentYear: 'termReports/getCurrentYear',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            formTeacherClass: 'termReports/getFormTeacherClass',
            codedComments: 'termReports/getCodedComments',
            table1Records: 'termReports/getTable1Records',
            pagination: 'termReports/getPagination',            
        }),
        studentName(){
            return this.studentRecord.first_name + ' ' + this.studentRecord.last_name;
        },
        classId(){
            return this.studentRecord.class_id;
        },
        recordSummary(){
            return [
                {title: 'Student\'s ID', model: 'student_id', select: false, disabled: true, section: 1},
                {title: 'Total No. of Sessions', model: 'possible_attendance', select: false, disabled: true, section: 1},
                {title: 'Sessions Absent', model: 'times_absent', select: false, disabled: false, section: 1},
                {title: 'Times Late', model: 'times_late', select: false, disabled: false, section: 1},
                {title: 'Dilligence', model: 'dilligence', select: true, disabled: false, section: 1},
                {title: 'Conduct', model: 'conduct', select: true, disabled: false, section: 1},
                {title: 'Progress', model: 'progress', select: true, disabled: false, section: 1},
            ]
        },        
        termName(){
            let termName = '';
            if(this.currentTerm == 1) termName =  'One';
            if(this.currentTerm == 2) termName = 'Two';
            if(this.currentTerm == 3) termName = 'Three'
            return termName
        }
    },
    watch: {
        studentRecord: {
            handler (val) {
                this.codedComment = { id: parseInt(val.rel_comment) }; 
                if(this.formTeacherClass != this.studentRecord.class_id){                    
                    this.disabled = true;
                    this.recordSummary.forEach(element => {
                        element.disabled = true;
                    })
                }                
            }
        },
    },
    data: () => ({
        academicPeriod: [
            { title: 'Year', value: '' },
            { title: 'Term', value: '' },
        ],
        grades: [
            {grade: 'A', scale: '80 - 100'},
            {grade: 'B', scale: '70 - 79'},
            {grade: 'C', scale: '60 - 69'},
            {grade: 'D', scale: '55 - 59'},
            {grade: 'E', scale: '50 - 54'},
            {grade: 'F', scale: '0 - 49'},
            {grade: 'U', scale: 'Ungraded'},            
            {grade: 'N/A', scale: 'Not Assessed'},            
            {grade: 'Abs', scale: 'Absent'},            
        ],
        grades2: [
            'Excellent',
            'Very Good',
            'Good',
            'Satisfactory',
            'Fair',
            'Poor'
        ],
        headers: [
            { text: 'Student ID', align: 'center', value: 'student_id', width: '75' },
            { text: 'First Name', align: 'left', value: 'first_name', width: '100' },
            { text: 'Last Name', align: 'left', value: 'last_name', width: '100' },
            { text: '', align: 'center', value: 'actions', sortable: false, width: '150' },
        ],
        options: {
            itemsPerPage: -1,
        },  
        codedComment: { id: null, comment: null }, 
       
        disabled: false,
        expand: false,
        overlay: false,
        search: '', 
          
    }),
    methods: {
        ...mapActions({ 
            postTable1Record: 'termReports/postTable1Record',
            getTable2Records: 'termReports/getTable2Records', 
            setErrorResponse: 'termReports/setErrorResponse',            
        }),        
        ...mapMutations({         
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setOverlay: 'termReports/setEditViewTermReportsOverlay',
            setPagination: 'termReports/setPagination',           
            setLoading: 'termReports/setEditViewTermReportsLoading',
            setSelectedStudentRecord: 'termReports/setSelectedStudentRecord',
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects',
            loading: 'termReports/setEditViewTermReportsLoading',
            setExpand: 'termReports/setEditViewTermReportsExpand',            
            setExpandTerms: 'termReports/setExpandTerms',
            setSrc: 'termReports/setSrc',   
        }),
        setRecordSummary(){
            //
        },
        async updateRecord(){
            console.log('updating...');
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            console.log(this.codedComment);
            this.studentRecord.rel_comment = this.codedComment.id;            
            //console.log(this.studentRecord);
            try{
                let response = await this.postTable1Record();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                console.log(response);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }
            
        },
        initialize(){            
            this.academicPeriod[0].value = this.academicYear;
            this.academicPeriod[1].value = this.termName;            
            //console.log(this.codedComments);            
        },
        searchStudent(){
            this.overlay = true;
            setTimeout(() => {
                this.expand = true;
            })
        },
        viewRecord(item){
            //console.log(item);
            this.expand = false;
            setTimeout(() => {
                this.overlay = false;
            }, 600);
            let recordIndex = this.table1Records.indexOf(item);
            this.recordNavigation(++recordIndex);
        },

        cancelSearch () {
            this.expand = false;
            setTimeout(() => {
                this.overlay = false;
            }, 600);
        },
         
        absValue ($event) {            
            if ($event.keyCode !== 69 && $event.keyCode !== 189 && !/\d/.test($event.key))
            return $event.preventDefault();            
        },

        async previousRecord () {
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.prev_page);           
        }, 

        nextRecord () {
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.next_page);            
        },

        async recordNavigation (pagination) {
            let response = null;
            this.setOverlay(true);
            this.loading(true);
            
            this.makePagination(pagination);
            this.setSelectedStudentRecord(this.table1Records[--pagination]);
            try {
                response = await this.getTable2Records();                
                this.setTable2Records(response.data.table2_records);
                this.setStudentSubjects(response.data.student_subjects);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }            
            this.setOverlay(false);
            this.loading(false);           
        },

        makePagination (data) {
            let prev_page = data;
            let current_page = data;
            let next_page = (current_page === this.pagination.last_page) ? null : ++data;
            prev_page = (current_page === 1) ? null : --prev_page;
            
            let pagination = {
                current_page: current_page,
                last_page: this.pagination.last_page,
                next_page: next_page,
                prev_page: prev_page,
            };
            this.setPagination(pagination);
        },

        nextClass () {
            this.setOverlay(true);
            this.setExpand(true);
            //this.setExpand(true);
        },

        printOneReport () {
            this.setOverlay(true);
            this.$nextTick(() => {
                this.setExpandTerms(true);
            });
            
            this.setSrc({
                baseUrl: process.env.VUE_APP_API_URI  + '/api/report-card',                
                year: this.currentYear,
                term: this.currentTerm,
                classId: this.studentRecord.class_id,
                studentId: this.studentRecord.student_id,
                               
            }); 
        },

        printClass () {
            this.setOverlay(true);
            this.$nextTick(() => {
                this.setExpandTerms(true);
            });
            
            this.setSrc({
                baseUrl: process.env.VUE_APP_API_URI  + '/api/report-card',                
                year: this.currentYear,
                term: this.currentTerm,
                classId: this.studentRecord.class_id,                               
            }); 
        }
        
    }
}
</script>

<style scoped>
    ::v-deep .v-input__slot{
        min-height: 0px !important;
        display: flex !important;
        align-items: center !important;
    }

    .v-btn__content{
        font-size: 0.9em;
    }

    ::v-deep .v-input__append-inner{
        margin-top: 0;
    }

    ::v-deep .v-select__selections{
        height: 32px;
        /*padding: 0 !important;*/
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    ::v-deep .v-text-field__slot input{
        text-align: center;
    }        

    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
    -moz-appearance: textfield;
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none; /* Standard */
    text-align: center;
    }
</style>